<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/css/all.min.css"
  integrity="sha512-3M00D/rn8n+2ZVXBO9Hib0GKNpkm8MSUU/e2VNthDyBYxKWG+BftNYYcuEjXlyrSO637tidzMBXfE7sQm0INUg=="
  crossorigin="anonymous"
  referrerpolicy="no-referrer"
/>

<!-- Header Start-->

<header id="header" class="header" role="banner" aria-label="Site header">
  <nav class="navbar navbar-expand-lg" id="navbar-main">
    <a [routerLink]="['/']" title="Home" rel="home" class="navbar-brand"
      ><img src="../../../assets/images/cagamas-logo-new01.jpg" alt="Home" class="img-fluid d-inline-block align-top"
    /></a>
    <div class="burger-wrapper">
      <button class="navbar-toggler burger-button" onclick="mobileMenu()">
        <span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span
        ><span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="CollapsingNavbar">
      <nav
        role="navigation"
        aria-labelledby="block-cagamas-main-menu-menu"
        id="block-cagamas-main-menu"
        class="block block-menu navigation menu--main"
      >
        <div class="mobile-menu-top">
          <a [routerLink]="['/']" title="Home" rel="home" class="navbar-brand-mobile"
            ><img
              src="../../../assets/images/cagamas-logo-new01.jpg"
              alt="Home"
              class="img-fluid d-inline-block align-top"
          /></a>
        </div>
        <ul data-region="primary_menu" id="block-cagamas-main-menu" class="clearfix nav navbar-nav menu">
          <li *ngIf="url != '/user-list'" class="nav-item menu-item">
            <a [routerLink]="['/dashboard']" class="nav-link"
              ><span>{{ 'HEADER.MENU1' | translate }} </span></a
            >
          </li>
          <li *ngIf="url != '/user-list'" class="nav-item menu-item pointer">
            <a (click)="getAKPK()" class="nav-link">
              <span>{{ 'HEADER.MENU2' | translate }}</span></a
            >
          </li>

          <!-- <li class="nav-item menu-item">
            <a [routerLink]="['application']" class="nav-link"
              ><span>{{ 'HEADER.MENU3' | translate }}</span></a
            >
          </li> -->
          <li *ngIf="url != '/user-list'" class="nav-item menu-item">
            <a *ngIf="!eligible" [routerLink]="['application']" class="nav-link"
              ><span>{{ 'HEADER.MENU3' | translate }}</span></a
            >
            <a
              *ngIf="eligible"
              href="javascript:void(0)"
              class="nav-link"
              title="You Already Have an Application In Progress"
              ><span>{{ 'HEADER.MENU3' | translate }}</span></a
            >

            <!-- <a   [routerLink]="['application']" class="nav-link" ><span>Submit Application</span></a> -->
          </li>
          <li class="nav-item menu-item submenu">
            <a href="javascript:void(0)" class="nav-link"
              ><span>{{ 'COMMON.WELCOME' | translate }} {{ username }}</span>
              <i class="fas fa-angle-down d-none d-sm-inline-block"></i
            ></a>
            <ul class="sub-menu-hld">
              <li>
                <button
                  class="dropdown-item"
                  style="white-space: pre-line"
                  (click)="isOldPassbord = false; open(mymodal)"
                >
                  {{ 'HEADER.MENU4' | translate }}
                </button>
              </li>
              <li>
                <button class="dropdown-item" (click)="logout()" translate>{{ 'HEADER.MENU5' | translate }}</button>
              </li>
            </ul>
          </li>
          <li *ngIf="url != '/user-list'" class="nav-item menu-item" style="display: flex">
            <a
              href="javascript:void(0)"
              style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a"
              (click)="switch('en')"
              >EN</a
            ><span style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400">|</span
            ><a
              href="javascript:void(0)"
              (click)="switch('ms')"
              style="text-decoration: none; padding: 10px 5px 0px 0px; color: #3a3a3a; font-weight: 400"
              >BM</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </nav>
</header>
<ng-template #mymodal let-modal>
  <div class="p-4">
    <div class="card-body">
      <h4 class="card-title text-left">
        {{ 'HEADER.MENU4' | translate }}
      </h4>
      <form (ngSubmit)="reset()" [formGroup]="resetnForm" novalidate>
        <div class="my-3">
          <label class="d-block mb-3">
            <div class="position-relative">
              <input
                type="{{ !_oldPass ? 'password' : 'text' }}"
                class="form-control"
                formControlName="old_password"
                autocomplete="nopefds"
                [placeholder]="'HEADER.FORM.FIELD1' | translate"
                required
              />
              <a (click)="togglepassword('O')" class="password-btn"
                ><i *ngIf="!_oldPass" class="far fa-eye-slash"></i> <i *ngIf="_oldPass" class="fas fa-eye"></i
              ></a>
            </div>
            <small
              [hidden]="resetnForm.controls['old_password'].valid || resetnForm.controls['old_password'].untouched"
              class="text-danger"
              translate
              >{{ 'COMMON.OPR' | translate }}
            </small>
          </label>

          <label class="d-block mb-3">
            <div class="position-relative">
              <input
                type="{{ !_defaultPass ? 'password' : 'text' }}"
                class="form-control"
                formControlName="password"
                autocomplete="nopefds"
                [placeholder]="'HEADER.FORM.FIELD2' | translate"
                required
              />
              <a (click)="togglepassword('P')" class="password-btn"
                ><i *ngIf="!_defaultPass" class="far fa-eye-slash"></i> <i *ngIf="_defaultPass" class="fas fa-eye"></i
              ></a>
            </div>
            <small
              *ngIf="resetnForm.controls['password'].errors?.['required'] && !resetnForm.controls['password'].untouched"
              class="text-danger"
              translate
            >
              {{ 'REGISTRATION.FORM.FIELD4ERROR1' }}</small
            >
            <small *ngIf="resetnForm.controls['password'].errors?.['minlength']" class="text-danger">{{
              'REGISTRATION.FORM.FIELD4ERROR3' | translate
            }}</small>
            <small
              *ngIf="resetnForm.controls['password'].errors?.['pattern'] && !resetnForm.controls['password'].errors?.['minlength']"
              class="text-danger"
              >{{ 'REGISTRATION.FORM.FIELD4ERROR2' | translate }}</small
            >
            <small class="text-danger" *ngIf="isOldPassbord">{{ 'COMMON.PSBDFOO' | translate }}</small>
          </label>

          <label class="d-block mb-3">
            <div class="position-relative">
              <input
                type="{{ !_secondryPass ? 'password' : 'text' }}"
                class="form-control"
                formControlName="confirmPassword"
                autocomplete="current-password"
                [placeholder]="'HEADER.FORM.FIELD3' | translate"
                required
              />
              <a (click)="togglepassword('S')" class="password-btn"
                ><i *ngIf="!_secondryPass" class="far fa-eye-slash"></i> <i *ngIf="_secondryPass" class="fas fa-eye"></i
              ></a>
            </div>
            <small
              *ngIf="resetnForm.controls['confirmPassword'].errors?.['required'] && !resetnForm.controls['confirmPassword'].untouched"
              class="text-danger"
              translate
            >
              {{ 'REGISTRATION.FORM.FIELD5ERROR1' | translate }}</small
            >
            <small *ngIf="resetnForm.controls['confirmPassword'].errors?.['CustomValidators']" class="text-danger">{{
              'REGISTRATION.FORM.FIELD5ERROR2' | translate
            }}</small>
          </label>
        </div>
        <div class="my-3 d-flex justify-content-between">
          <button class="btn btn-primary btn-custom" type="submit" [disabled]="resetnForm.invalid || isLoading">
            <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
            <span translate>{{ 'FORGOT.BUTTON2' | translate }}</span>
          </button>
          <button type="button" class="btn btn-outline-dark rounded-0" (click)="modal.close('Save click')">
            {{ 'APPLICATION.FORM3.FORM3BUTTON1' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">
      {{ 'APPLICATION.FORM3.FORM3BUTTON1' | translate }}
    </button>
  </div> -->
</ng-template>
