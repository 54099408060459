import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
//import { environment } from '@env/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { NationalityValidators } from '../../providers/NationalityValidators';
import { DatePipe } from '@angular/common';
import { EligibilityCheckService } from '@app/auth/eligibility--check.service';
import { browserRefresh } from '@app/app.component';
import { CredentialsService } from '@app/auth';
import { UserService } from '@app/auth/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-application-part-one',
  templateUrl: './application-part-one.component.html',
  styleUrls: ['./application-part-one.component.scss'],
})
export class ApplicationPartOneComponent implements OnInit {
  quote: string | undefined;
  isLoading = false;
  ageerror = false;
  applicForm!: FormGroup;
  version: string | null = environment.version;
  applicationpartone: any;
  postcodeerror = false;
  credentials: any = {};

  email: any;
  applicationId: any;
  createdDate: any;
  applicationType: any;
  employerAddress: any;
  employerName: any;
  handPhoneNumber: any;
  employerPostCode: any;
  initialPayment: any;
  jointApplicant: any;
  lumSumUsage: any;
  maritalStatus: any;
  monthlyIncome: any;
  nameAsPerIc: any;
  nationality: any;
  nricNumber: any;
  occupation: any;
  userDetailData: any = [];
  tryagain: string = '';
  constructor(
    private translate: TranslateService,
    private userservice: UserService,
    private credentialsService: CredentialsService,
    private eligibilityCheck: EligibilityCheckService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createForm();
    this.setUserCategoryValidators();
    this.usersubmittedapplication();
  }

  ngOnInit() {
    if (browserRefresh == true) {
      this.router.navigate(['/dashboard']);
    }
    this.credentials = this.credentialsService.credentials;
    this.translate.get('APPLICATION').subscribe((data: any) => {
      this.tryagain = data.FORM1.TRYAGAIN;
    });
  }
  private createForm() {
    this.applicForm = this.formBuilder.group(
      {
        joint: ['', Validators.required],
        applicationType: ['', Validators.required],
        salutation: [''],
        applicant_name: ['', Validators.required],
        nric_no: ['', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]],
        res_address: ['', Validators.required],
        postcode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
        email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        // res_tel: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(11)]],
        res_tel: ['', [Validators.minLength(9), Validators.maxLength(11)]],
        hp_tel: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(11)]],
        dob: ['', Validators.required],
        race: ['', Validators.required],
        nationality: ['Malaysian', Validators.required],
        sex: ['', Validators.required],
        marital_status: ['', Validators.required],
        no_dep: [''],
        no_dep1: [''],
        no_dep2: [''],
        no_dep3: [''],
        no_dep4: [''],
        no_dep5: [''],
        present_house: ['', Validators.required],
        occupation: ['', Validators.required],
        name_of_employer: [''],
        address_of_employer: [''],
        postcode_of_employer: [''],
        purpose_of_ssb: ['', Validators.required],
        payout_option: ['', Validators.required],
        usage_of_lumpsum: [''],
        cost_expenses: ['', Validators.required],
      }
      //{ validator: NationalityValidators('nationality') }
    );
  }
  setUserCategoryValidators() {
    const institutionControl = this.applicForm.get('usage_of_lumpsum');
    this.applicForm.get('payout_option')?.valueChanges.subscribe((userCategory) => {
      if (userCategory === 'Lumpsum & Monthly Payout') {
        this.applicForm.controls['usage_of_lumpsum'].setValidators([Validators.required]);
      } else {
        institutionControl?.setValidators(null);
      }
      institutionControl?.updateValueAndValidity();
    });
  }
  usersubmittedapplication = async () => {
    await this.userservice.getapplication().subscribe((res) => {
      // const _res:any = res.filter((x:any)=> {return x.orderProgress==="FORM1_DETAILS" || x.orderProgress==="FORM2_DETAILS" && x.orderStatus ==="DRAFT"})
      const _res: any = res.filter((x: any) => {
        return x.orderStatus === 'DRAFT';
      });
      if (_res.length > 0) {
        this.userDetailData.length = 0;
        this.userDetailData = _res;
        console.log(this.userDetailData, 'one this.userDetailData');

        this.applicForm.controls['email'].setValue(this.userDetailData[0].email);
        this.applicForm.controls['hp_tel'].setValue(this.userDetailData[0].handPhoneNumber);
        this.applicForm.controls['postcode'].setValue(this.userDetailData[0].postCode);
        this.applicForm.controls['applicationType'].setValue(this.userDetailData[0].applicationType);
        if (this.userDetailData[0].hasOwnProperty('dateOfBirth')) {
          const myArray = this.userDetailData[0].dateOfBirth.split('/');
          const dobformatted = myArray[2] + '-' + myArray[1] + '-' + myArray[0];
          this.applicForm.controls['dob'].setValue(dobformatted);
        }

        this.applicForm.controls['address_of_employer'].setValue(this.userDetailData[0].employerAddress);
        this.applicForm.controls['name_of_employer'].setValue(this.userDetailData[0].employerName);
        if (this.userDetailData[0].hasOwnProperty('employerPostCode') && this.userDetailData[0].employerPostCode != 0) {
          this.applicForm.controls['postcode_of_employer'].setValue(this.userDetailData[0].employerPostCode);
        }
        this.applicForm.controls['cost_expenses'].setValue(this.userDetailData[0].initialPayment);

        this.applicForm.controls['joint'].setValue(this.userDetailData[0].jointApplicant);
        this.applicForm.controls['usage_of_lumpsum'].setValue(this.userDetailData[0].lumSumUsage);
        this.applicForm.controls['marital_status'].setValue(this.userDetailData[0].maritalStatus);
        // this.applicForm.controls ['monthlyIncome'] .setValue(this.userDetailData[0].monthlyIncome);
        this.applicForm.controls['applicant_name'].setValue(this.userDetailData[0].nameAsPerIc);
        //this.applicForm.controls ['nationality'] .setValue(this.userDetailData[0].nationality);
        this.applicForm.controls['nric_no'].setValue(this.userDetailData[0].nricNumber);
        this.applicForm.controls['occupation'].setValue(this.userDetailData[0].occupation);

        this.applicForm.controls['present_house'].setValue(this.userDetailData[0].presentHouse);
        this.applicForm.controls['race'].setValue(this.userDetailData[0].race);
        if (this.userDetailData[0].hasOwnProperty('resPhoneNumber') && this.userDetailData[0].resPhoneNumber != 0) {
          this.applicForm.controls['res_tel'].setValue(this.userDetailData[0].resPhoneNumber);
        }
        this.applicForm.controls['res_address'].setValue(this.userDetailData[0].residentialAddress);
        this.applicForm.controls['salutation'].setValue(this.userDetailData[0].salutation);
        this.applicForm.controls['sex'].setValue(this.userDetailData[0].sex);
        this.applicForm.controls['purpose_of_ssb'].setValue(this.userDetailData[0].ssbApplicationPurpose);
        this.applicForm.controls['payout_option'].setValue(this.userDetailData[0].ssbPayoutOption);
        this.applicForm.controls['no_dep'].setValue(this.userDetailData[0].noOfDependantsAndAges.noOfDependants);

        this.applicForm.controls['no_dep1'].setValue(
          this.userDetailData[0].noOfDependantsAndAges.agesOfDependants.ageOfDependant1
        );
        this.applicForm.controls['no_dep2'].setValue(
          this.userDetailData[0].noOfDependantsAndAges.agesOfDependants.ageOfDependant2
        );
        this.applicForm.controls['no_dep3'].setValue(
          this.userDetailData[0].noOfDependantsAndAges.agesOfDependants.ageOfDependant3
        );
        this.applicForm.controls['no_dep4'].setValue(
          this.userDetailData[0].noOfDependantsAndAges.agesOfDependants.ageOfDependant4
        );
        this.applicForm.controls['no_dep5'].setValue(
          this.userDetailData[0].noOfDependantsAndAges.agesOfDependants.ageOfDependant5
        );
        this.applicationId = this.userDetailData[0].applicationId;
      }
    });
  };
  apkpk_one() {
    if (this.applicForm.valid) {
      console.log(this.applicForm.value.dob);
      const dateSendingToServer = new DatePipe('en-US').transform(this.applicForm.value.dob, 'dd/MM/yyyy');
      console.log(dateSendingToServer, 'dateSendingToServer');
      localStorage.setItem('applcation_type', this.applicForm.value.joint);
      this.applicationpartone = {
        applicationId: this.applicationId,
        applicationType: this.applicForm.value.applicationType,
        dateOfBirth: dateSendingToServer,
        email: this.applicForm.value.email,
        employerAddress: this.applicForm.value.address_of_employer,
        employerName: this.applicForm.value.name_of_employer,
        employerPostCode: this.applicForm.value.postcode_of_employer,
        handPhoneNumber: this.applicForm.value.hp_tel,
        initialPayment: this.applicForm.value.cost_expenses,
        jointApplicant: this.applicForm.value.joint,
        lumSumUsage:
          this.applicForm.value.payout_option != 'Monthly Payout only' ? this.applicForm.value.usage_of_lumpsum : '',
        maritalStatus: this.applicForm.value.marital_status,
        nameAsPerIc: this.applicForm.value.applicant_name,
        nationality: this.applicForm.value.nationality,
        noOfDependantsAndAges: {
          noOfDependants: this.applicForm.value.no_dep,
          agesOfDependants: {
            ageOfDependant1: this.applicForm.value.no_dep1,
            ageOfDependant2: this.applicForm.value.no_dep2,
            ageOfDependant3: this.applicForm.value.no_dep3,
            ageOfDependant4: this.applicForm.value.no_dep4,
            ageOfDependant5: this.applicForm.value.no_dep5,
          },
        },
        nricNumber: this.applicForm.value.nric_no,
        occupation: this.applicForm.value.occupation,
        orderProgress: 'FORM1_DETAILS',
        orderStatus: 'DRAFT',
        postCode: this.applicForm.value.postcode,
        presentHouse: this.applicForm.value.present_house,
        race: this.applicForm.value.race,
        resPhoneNumber: this.applicForm.value.res_tel,
        residentialAddress: this.applicForm.value.res_address,
        salutation: this.applicForm.value.salutation,
        sex: this.applicForm.value.sex,
        ssbApplicationPurpose: this.applicForm.value.purpose_of_ssb,
        ssbPayoutOption: this.applicForm.value.payout_option,
      };

      this.userservice.submit_akpk_one(this.applicationpartone).subscribe(
        (data) => {
          console.log(data);
          localStorage.setItem('part-one', JSON.stringify(data));
          this.router.navigate(['/application-part-two']);
        },
        (error) => {
          Swal.fire(this.tryagain, '', 'info');
        }
      );
      //this.router.navigate(['/application-part-two']);
    } else {
      // localStorage.setItem('applcation_type', this.applicForm.value.joint);
      // this.router.navigate(['/application-part-two']);
      this.markFormGroupTouched(this.applicForm);
    }
  }

  check_date(e: any) {
    // const convertAge = new Date(e.target.value);
    // const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    // var ages = Math.floor(timeDiff / (1000 * 3600 * 24) / 365);
    var today: any = new Date();
    var birthDate = new Date(e.target.value);
    var ages = Math.floor((today - new Date(birthDate).getTime()) / 3.15576e10);
    console.log('ages>>', ages);
    if (ages < 55) {
      this.ageerror = true;
    } else if (ages > 55) {
      this.ageerror = false;
    } else if (ages == 55) {
      this.ageerror = false;
    }
  }
  post_code_validate() {
    this.eligibilityCheck.postcode(this.applicForm.value.postcode).subscribe(
      (data) => {},
      (error) => {
        if (error.status == 200) {
          this.postcodeerror = false;
        } else {
          this.postcodeerror = true;
          this.applicForm.controls['postcode'].setValue('');
        }
      }
    );
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: { markAsTouched: () => void; controls: any[] }) => {
      control.markAsTouched();
      if (control.controls) {
        control.controls.forEach((c: FormGroup) => this.markFormGroupTouched(c));
      }
    });
  }
}
