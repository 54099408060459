import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { CustomValidators } from '../../providers/CustomValidators';
import Swal from 'sweetalert2';
import { UserService } from '@app/auth/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  UserList: any = [];
  closeResult: string = '';
  applicationlist: any = [];
  allapplicationlist: any = [];
  searchText: any;
  filteredUsers: any;
  filterBy: any = [];
  allchecked: boolean = false;
  allnotpresent: boolean = false;
  p: number = 1;
  page: number = 1;
  _filterModel = {
    applicationno: '',
    date: '',
    cemail: '',
    email: '',
    status: '',
    postcode: '',
    pageNo: '0',
    pageSize: 1000,
  };
  remarksModel = {
    applicationId: '',
    orderStatus: '',
    remarks: '',
  };

  constructor(
    private translateservice: TranslateService,
    private userservice: UserService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getuserData();
    this.translateservice.setDefaultLang('en');
    this.translateservice.use('en');
  }

  open(content: any, applicationId: any) {
    this.remarksModel.applicationId = applicationId;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        // this.resetnForm.markAsPristine();
        // this.resetnForm.reset();
      },
      (reason) => {
        this.closeResult = `Dismissed `;
      }
    );
  }

  getuserData = async () => {
    var params = {
      pageNo: '0',
      page_size: 10,
    };
    await this.userservice.getUserData().subscribe(
      (responce) => {
        this.UserList = responce;
        this.allapplicationlist = [];
        this.UserList.forEach((item: any) => {
          this.allapplicationlist.push(item.applicationId);
        });
      },
      (err) => {
        //console.log(err);
      }
    );
  };
  generatecsv(applicationId: any) {
    //console.log(this.UserList);
    this.userservice.getexcellist(applicationId);
  }
  checkedapplication(applicationId: any) {
    this.allnotpresent = false;
    if (this.applicationlist.length > 0) {
      const index = this.applicationlist.indexOf(applicationId);
      if (index > -1) {
        // only splice array when item is found
        this.applicationlist.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        this.applicationlist.push(applicationId);
      }
    } else {
      this.applicationlist.push(applicationId);
    }
    this.allapplicationlist.forEach((item: any) => {
      if (this.allapplicationlist.length > 0) {
        const index2 = this.applicationlist.indexOf(item);

        if (index2 > -1) {
        } else {
          this.allnotpresent = true;
        }
      } else {
      }
    });
    if (this.allnotpresent == false) {
      this.allchecked = true;
    } else {
      this.allchecked = false;
    }
  }
  checkedallapplication(event: any) {
    if (event.target.checked == true) {
      this.allchecked = true;
      this.UserList.forEach((item: any) => {
        if (this.applicationlist.length > 0) {
          const index = this.applicationlist.indexOf(item.applicationId);
          if (index > -1) {
            // only splice array when item is found
            //this.applicationlist.splice(index, 1); // 2nd parameter means remove one item only
          } else {
            this.applicationlist.push(item.applicationId);
          }
        } else {
          this.applicationlist.push(item.applicationId);
        }
      });
    } else {
      this.allchecked = false;
      this.applicationlist = [];
    }
  }
  genratecsvmultiple() {
    this.userservice.getcsvmultiple(this.applicationlist);
  }
  genrateexcelmultiple() {
    this.userservice.genrateexcelmultiple(this.applicationlist);
  }

  _onSearch = async () => {
    await this.userservice._searchUser(this._filterModel).subscribe(
      (_res: any) => {
        this.UserList = _res['ssbApplicationInfos'] ?? [];
        this.applicationlist = [];
        this.allchecked = false;
        this.allapplicationlist = [];
        this.p = 1;
        this.page = 1;
        this.UserList.forEach((item: any) => {
          this.allapplicationlist.push(item.applicationId);
        });
      },
      (err) => {
        //console.log(err);
      }
    );
  };
  onTableDataChange(event: any) {
    this.page = event;
    //this._onSearch();
  }
  remarkssubmit = async () => {
    await this.userservice.statuschange(this.remarksModel).subscribe(
      (res: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Application Status Change Successfully',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Ok',
        }).then((result) => {
          this.getuserData();
        });
      },
      (err) => {
        //console.log(err);
      }
    );
  };

  _goToView = async (item: any) => {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        user: JSON.stringify(item),
      },
    };
    await this.router.navigateByUrl(`/application-view/${item.applicationId}`, { state: item });
    // await this.router.navigate(['/application-view',item.applicationId],navigationExtras);
  };
  reload() {
    this._filterModel = {
      applicationno: '',
      date: '',
      cemail: '',
      email: '',
      status: '',
      postcode: '',
      pageNo: '0',
      pageSize: 1000,
    };
    this.getuserData();
  }
}
