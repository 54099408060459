import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPassComponent } from './forgot-pass.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { LoginComponent } from './login.component';
import { RegistrationComponent } from './registration.component';
import { LandingComponent } from './landing.component';
import { EligibilityCheckComponent } from './eligibility-check/eligibility-check.component';
import { RegisterSuccessfullyComponent } from './register-successfully/register-successfully.component';
import { VerifyuserComponent } from './verifyuser/verifyuser.component';
import { VerifiedSuccessfullyComponent } from './verified-successfully/verified-successfully.component';
import { ForgotEmailComponent } from './forgot-email/forgot-email.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';

const routes: Routes = [
  { path: '', redirectTo: '/main-page', pathMatch: 'full' },

  //**Login Path */
  { path: 'login', component: LoginComponent, data: { title: marker('Login'), requestFrom: 'ROLE_CUSTOMER' } },
  {
    path: 'cagamas-user/login',
    component: LoginComponent,
    data: { title: marker('Cagamas Login'), requestFrom: 'ROLE_CAGAMAS_USER' },
  },
  {
    path: 'admin/login',
    component: LoginComponent,
    data: { title: marker('Admin Login'), requestFrom: 'ROLE_IT_ADMIN' },
  },

  //{ path:"comingsoon", component: ComingsoonComponent, data: { title: marker('Coming Soon')} },
  { path: 'registration', component: RegistrationComponent, data: { title: marker('Registration') } },

  { path: 'main-page', component: LandingComponent, data: { title: marker('Main Page') } },
  {
    path: 'eligibility-criteria',
    component: EligibilityCheckComponent,
    data: { title: marker('Eligibility Criteria') },
  },
  { path: 'verifyuser/:emailId', component: VerifyuserComponent, data: { title: marker('Verify User') } },
  { path: 'verified-successfully', component: VerifiedSuccessfullyComponent, data: { title: marker('Verify User') } },

  //**Forgot Path */
  {
    path: 'forgot-password-reset/:emailId',
    component: ForgotPassComponent,
    data: { title: marker('Forgot Password'), requestFrom: 'ROLE_CUSTOMER' },
  },
  {
    path: 'forgot-password-reset/:emailId',
    component: ForgotPassComponent,
    data: { title: marker('Cagamas Forgot Email'), requestFrom: 'ROLE_CUSTOMER' },
  },
  {
    path: 'cagamas-user-forgot-password-reset/:emailId',
    component: ForgotPassComponent,
    data: { title: marker('Forgot Password'), requestFrom: 'ROLE_CAGAMAS_USER' },
  },
  {
    path: 'cagamas-user/forgot-password-reset/:emailId',
    component: ForgotPassComponent,
    data: { title: marker('Cagamas Forgot Email'), requestFrom: 'ROLE_CAGAMAS_USER' },
  },
  {
    path: 'admin-forgot-password-reset/:emailId',
    component: ForgotPassComponent,
    data: { title: marker('Forgot Password'), requestFrom: 'ROLE_IT_ADMIN' },
  },
  {
    path: 'admin/forgot-password-reset/:emailId',
    component: ForgotPassComponent,
    data: { title: marker('Cagamas Forgot Email'), requestFrom: 'ROLE_IT_ADMIN' },
  },

  {
    path: 'forgot-password',
    component: ForgotEmailComponent,
    data: { title: marker('Forgot Email'), requestFrom: 'ROLE_CUSTOMER' },
  },
  {
    path: 'cagamas-user/forgot-password',
    component: ForgotEmailComponent,
    data: { title: marker('Cagamas Forgot Email'), requestFrom: 'ROLE_CAGAMAS_USER' },
  },
  {
    path: 'admin/forgot-password',
    component: ForgotEmailComponent,
    data: { title: marker('Cagamas Forgot Email'), requestFrom: 'ROLE_IT_ADMIN' },
  },
  //**Forget Password End */
  {
    path: 'register-successfully',
    component: RegisterSuccessfullyComponent,
    data: { title: marker('Register Successfully') },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
