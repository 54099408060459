import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpBackend } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RouteReusableStrategy, ApiPrefixInterceptor, ErrorHandlerInterceptor, SharedModule } from '@shared';
import { AuthModule } from '@app/auth';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AboutModule } from './about/about.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicationPartOneModule } from './application-part-one/application-part-one.module';
import { ApplicationPartTwoModule } from './application-part-two/application-part-two.module';
import { ApplicationPartThreeModule } from './application-part-three/application-part-three.module';
import { UserListModule } from './user-list/user-list.module';
import { ApplicationViewModule } from './application-view/application-view.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SubmittedPageModule } from './submitted-page/submittedpage.module';
// localization module import
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPaginationModule } from 'ngx-pagination';
/** Charu Start :- Language Service Initialization */

export function HttpLoaderFactory(handler: HttpBackend) {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/** Charu End :- Language Service Initialization */

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    NgbModule,
    SharedModule,
    ShellModule,
    HomeModule,
    ApplicationPartOneModule,
    AboutModule,
    ApplicationPartTwoModule,
    ApplicationPartThreeModule,
    DashboardModule,
    NgxPaginationModule,
    UserListModule,
    ApplicationViewModule,
    AuthModule,
    SubmittedPageModule,
    AppRoutingModule,
    ReactiveFormsModule,

    // must be imported as the last module as it contains the fallback route
  ],
  exports: [TranslateModule],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    TranslateService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
